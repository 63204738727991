import { Auth } from 'aws-amplify';

export const useAmplifly = () => ({
    validateToken: async (token: string) => {
        console.log('Validating token...');

        //TODO
        const user = await Auth.currentAuthenticatedUser();

        if (user) {
            const roles = user.signInUserSession.accessToken.payload['cognito:groups'];
            return {
                user: {
                    id: user.attributes.sub,
                    username: user.username,
                    email: user.attributes.email,
                    name: '',
                    roles,
                },
                token
            };
        }

        return {
            user: null,
            token: '',
        }

    },

    signin: async (username: string, password: string) => {
        try {
            const user = await Auth.signIn({ username, password });

            const token = user.signInUserSession.accessToken.jwtToken;
            const roles = user.signInUserSession.accessToken.payload['cognito:groups'];

            return {
                user: {
                    id: user.attributes.sub,
                    username: user.username,
                    email: user.attributes.email,
                    name: '',
                    roles,
                },
                token
            };

        } catch (err) {
            console.log(err);
            return {
                user: null,
                token: '',
            };
        }

    },

    signout: async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }

    }
})