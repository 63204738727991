import { ReactNode } from "react";

interface PageControllerProps {
    children: ReactNode;
}


const PageController = ({ children }: PageControllerProps) => {
    return (
        <div className="flex justify-end">
            {children}
        </div>
    );
};

export default PageController;