import React, { useEffect, useRef, useState } from "react";
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import PageForm from "../../templates/PageForm";
import Tab from "../../molecules/Tab";
import TextEdit from "../../molecules/TextEdit";
import SpanList, { ErrorItemType } from "../../atoms/SpanList";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProgressBar from "../../atoms/ProgressBar";

type ErrorRow = {
    socialId: string
    errorDetail: string
}

type BatchItem = {
    socialId: string
    partnerId: number
    batchGroupId: string
}

type BatchAnalysisPresenter = {
    id: string
    formattedCreatedAt: string
    formattedModifiedAt: string

    partnerId: number
    partnerGovTaxId: string
    partnerFantasyName: string
    partnerLegalName: string

    status: string;
    formattedStatus: string;
    fileName: string;
    fileType: string;
    fileSize: number;
    username: string;

    totalRows: number;
    totalProcessing: number;
    totalSuccess: number;
    totalInvalids: number;
    totalErrors: number;
    progress: number;

    success: BatchItem[]
    invalids: string[]
    errors: ErrorRow[]
}

const MARGIN_TOP_DEFAULT = 380;
const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/ws`;
const BATCH_ANALYSIS_LIST = "/analysis/batch-analysis/0/40/createdAt/DESC";
const ENV = process.env.NODE_ENV;


const BatchAnalysisView = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [content, setContent] = useState<BatchAnalysisPresenter>();
    const api = useRef('');
    const navigate = useNavigate();

    const { id } = useParams();

    const calculateWindowHeight = () => {
        setWindowHeight(window.innerHeight - MARGIN_TOP_DEFAULT);
    };
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight - MARGIN_TOP_DEFAULT);

    const [searchParams] = useSearchParams();
    const tabIndex = searchParams.get('tab') || "2";

    const token = localStorage.getItem('authToken');

    useEffect(() => {

        const socket = new SockJS(`${BASE_REFERRAL_URL}?token=${token}`);
        const client = Stomp.over(socket);
        if (ENV === 'production') {
            client.debug = () => { };
        }
        window.addEventListener('resize', calculateWindowHeight);

        const onConnected = () => {
            client.send('/app/join', {}, id);

            client.subscribe('/topic/batch-analysis-' + id, (payload: any) => {
                const { presenter, apiName, apiVersion } = JSON.parse(payload.body);
                api.current = `${apiName} - ${apiVersion}`;
                setContent(presenter);
                setIsLoading(false);
            });
        };

        const onError = (error: any) => {
            console.error('WebSocket error:', error);
            if (error.headers?.message === 'Unauthorized') {
                navigate("/denied")
            }
        };

        client.connect({}, onConnected, onError);

        return () => {
            window.removeEventListener('resize', calculateWindowHeight);
        };
    }, []);

    //TODO extract to another component
    const getCssColorBy = (status: string | undefined) => {
        if (!status) return 'bg-gray-500';

        const cssClazz: Record<string, string> = {
            'PROCESSING': 'bg-blue-500',
            'CREATED': 'bg-gray-500',
            'ERROR': 'bg-red-500',
            'PROCESSED': 'bg-green-500'
        }
        return cssClazz[status];
    };

    return (
        <PageForm
            pageTitle='Análise em lote'
            apiVersion={api.current}
            isLoading={isLoading}
            breadcrumb={[{ label: 'Análises em lote', url: BATCH_ANALYSIS_LIST }, { label: 'Análise em lote', url: '#' }]}
        >
            {{
                controlSlot: (
                    <ProgressBar
                        label="Enviando para o motor de crédito..."
                        progress={content?.progress ?? 0}
                        visible={content?.status == 'PROCESSING'}
                    />
                ),

                formSlot: (
                    <div className="p-2 m-2">
                        <div className="grid grid-cols-12 gap-4">

                            <div className="col-span-2">
                                <TextEdit label="Inicio do processamento:" value={content?.formattedCreatedAt} disabled />
                            </div>

                            <div className="col-span-2">
                                <TextEdit label="Última atualização:" value={content?.formattedModifiedAt} disabled />
                            </div>

                            <div className="col-span-3">
                                <TextEdit label="Arquivo:" value={content?.fileName} disabled />
                            </div>

                            <div className="col-span-3">
                                <TextEdit label="Usuário:" value={content?.username} disabled />
                            </div>

                            <div className="col-span-2 text-right">
                                <span className={`rounded p-2 text-xs font-bold text-white ${getCssColorBy(content?.status)}`}>
                                    {content?.formattedStatus}
                                </span>
                            </div>

                            <h2 className="mb-2 text-base text-gray-700 font-medium col-span-12">Parceira</h2>

                            <div className="col-span-2">
                                <TextEdit label="CNPJ:" value={content?.partnerGovTaxId} disabled />
                            </div>

                            <div className="col-span-4">
                                <TextEdit label="Nome fantasia:" value={content?.partnerFantasyName} disabled />
                            </div>

                            <div className="col-span-6">
                                <TextEdit label="Razão social:" value={content?.partnerLegalName} disabled />
                            </div>

                            <h2 className="mb-2 text-base text-gray-700 font-medium col-span-12">
                                CPFs
                                <span className="text-xs text-gray-400 font-normal ml-2">
                                    {content?.totalRows} registros
                                </span>
                            </h2>

                        </div>
                        <Tab tabItems={[{ id: "tab-1", title: 'Inválidos', index: 0, badge: content?.totalInvalids },
                        { id: "tab-2", title: 'Erros', index: 1, badge: content?.totalErrors },
                        { id: "tab-3", title: 'Sucesso', index: 2, badge: content?.totalSuccess },
                        { id: "tab-4", title: 'Em processamento', disabled: true, index: 3, badge: content?.totalProcessing }]}
                            tabIndex={Number(tabIndex)} />
                        <div className="mt-4">
                            {tabIndex === "0" ?
                                <div className="relative overflow-y-auto" style={{ height: windowHeight }} >
                                    {content?.invalids?.length ?? 0 > 0 ?

                                        <SpanList
                                            itemType={ErrorItemType.ERROR}
                                            items={content?.invalids.map(item => ({ label: item }))}
                                        />
                                        :
                                        <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3">
                                            <p className="text-sm">Nenhum registro de inválidos.</p>
                                        </div>
                                    }
                                </div> : null
                            }

                            {tabIndex === "1" ?
                                <div className="relative overflow-y-auto" style={{ height: windowHeight }} >
                                    {content?.errors?.length ?? 0 > 0 ?

                                        <SpanList
                                            itemType={ErrorItemType.ERROR}
                                            items={content?.errors
                                                .map(item => ({
                                                    label: item.socialId,
                                                    tooltip: item.errorDetail
                                                })
                                                )}
                                        />
                                        :
                                        <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3">
                                            <p className="text-sm">Nenhum registro de erro.</p>
                                        </div>
                                    }
                                </div> : null
                            }

                            {tabIndex === "2" ?
                                <div className="relative overflow-y-auto" style={{ height: windowHeight }} >
                                    {content?.success?.length ?? 0 > 0 ?
                                        <SpanList
                                            itemType={ErrorItemType.SUCCESS}
                                            items={content?.success
                                                .map(item => ({
                                                    label: item.socialId
                                                })
                                                )}
                                        />
                                        :
                                        <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3">
                                            <p className="text-sm">Nenhum registro de sucesso.</p>
                                        </div>
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>
                )
            }}
        </PageForm >
    );
};

export default BatchAnalysisView;
