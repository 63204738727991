import { FC, Fragment } from 'react';
import { MdArrowRight, MdArrowLeft, MdLastPage, MdFirstPage } from 'react-icons/md';
import "./APagination.css"

interface APaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const APagination: FC<APaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {

    const pagesToShow = 20;
    const pageStart = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const pageEnd = Math.min(totalPages, pageStart + pagesToShow - 1);

    return (
        <Fragment>
            <div className="inline-flex text-sm h-full border-l items-center pl-4 flex-none w-[178px] ">
                <label htmlFor="page" className='text-sm font-normal text-gray-500'>Página: </label>
                <select
                    id="page"
                    className='text-sm block p-2 text-gray-900 bg-transparent peer appearance-none w-16'
                    value={currentPage}
                    onChange={(e) => {
                        const newItemsPerPage = parseInt(e.target.value, 10);
                        onPageChange(newItemsPerPage);
                    }}
                >
                    {Array.from({ length: pageEnd - pageStart + 1 }, (_, index) => {
                        const pageNumber = pageStart + index;
                        return (
                            <option key={index} value={pageNumber}>
                                {pageNumber}
                            </option>
                        );
                    })};
                </select>
                <span className="mr-2">de</span>
                <span className="mr-4 font-semibold text-gray-900">{totalPages}</span>
            </div>

            <div className="flex flex-none w-48 h-full">
                <button
                    className={`flex items-center justify-center px-3 h-full leading-tight border-l border-gray-300 text-gray-500 bg-white hover:text-gray-700 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:cursor-pointer'}`}
                    onClick={() => onPageChange(1)}
                    title='Primeira página'
                    disabled={currentPage === 1}
                >
                    <MdFirstPage className='w-6 h-6' />
                </button>
                <button
                    className={`flex items-center justify-center px-3 h-full leading-tight border-l border-gray-300 text-gray-500 bg-white hover:text-gray-700 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:cursor-pointer'}`}
                    onClick={() => onPageChange(currentPage - 1)}
                    title='Página anterior'
                    disabled={currentPage === 1}
                >
                    <MdArrowLeft className='w-6 h-6' />
                </button>

                <button
                    className={`flex items-center justify-center px-3 h-full leading-tight border-l border-gray-300 text-gray-500 bg-white hover:text-gray-700 ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:cursor-pointer'}`}
                    onClick={() => onPageChange(currentPage + 1)}
                    title='Próxima página'
                    disabled={currentPage === totalPages}
                >
                    <MdArrowRight className='w-6 h-6' />
                </button>

                <button
                    className={`flex items-center justify-center px-3 h-full leading-tight border-l border-gray-300 text-gray-500 bg-white hover:text-gray-700 ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:cursor-pointer'}`}
                    onClick={() => onPageChange(totalPages)}
                    title='Última página'
                    disabled={currentPage === totalPages}
                >
                    <MdLastPage className='w-6 h-6' />
                </button>
            </div>
        </Fragment>
    );
};

export default APagination;