import { useCallback, useEffect, useRef, useState } from 'react';
import { Auth } from "aws-amplify";

const useFetch = (url: string, corsDisable?: boolean) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const [data, setData] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);
    const token = useRef('');

    const fetchData = useCallback(async (token: string) => {
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setData(data);
            setIsLoading(false);
        } catch (error: unknown) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError(String(error));
            }
            setIsLoading(false);
        }
    }, [url, corsDisable]);

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        Auth.currentSession()
            .then((user) => user.getAccessToken().getJwtToken())
            .then((tkn) => {
                if (isMounted) {
                    token.current = tkn;
                    fetchData(tkn);
                }
            })
            .catch((error) => {
                if (isMounted) {
                    setIsTokenError(true);
                    setError(error);
                    setIsLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [url, fetchData]);

    return { isLoading, data, error, isTokenError };
};

export default useFetch;