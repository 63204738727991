import React, { useEffect, useRef, useState } from 'react';
import CurrencyInput from '../../molecules/CurrencyInput';
import ToggleSwitch from '../../atoms/ToogleSwitch';
import SelectTextField from '../../molecules/SelectTextField';
import PageForm from '../../templates/PageForm';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PresentationModal from '../../organisms/PresentationModal';
import { useSignal } from '@preact/signals-react';
import { Auth } from 'aws-amplify';

import { ErrorField } from '../../../types/ErrorField';
import { currencyWithPrefix } from '../../../utils/currencyUtils';
import { getErrorMessage } from '../../../utils/formErrorUtils';

type PolicyProperty = {
    lastUpdate: string,
    updatedBy: string,
    maxCreditLimit: number,
    maxCreditLimitPercent: number,
    validateMaxCreditLimitPercent: boolean,

    isCreditLimitRounded: boolean,
    ruralCreditLimitDecimalPlaces: number,
    greenThreshold: number,
    yellowThreshold: number,
}

const CONFIGURATION_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/configurations`;

const ReferralConfiguration: React.FC = () => {

    const apiVersion = useRef('');
    const lastupdatedBy = useRef('');
    const navigate = useNavigate();
    const isModalOpen = useSignal(false);
    const changeList = useSignal<string[]>([]);

    const { isLoading, data, error, isTokenError } = useFetch(`${CONFIGURATION_URL}`);

    const errorFields = useSignal<ErrorField[]>([]);

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    const form = useSignal({
        maxLimit: 0,
        isBlockedByPercent: false,
        maxLimitPercentage: 0,
        decimalPlaces: 2,
        isCreditLimitRounded: false,
        greenThreshold: 20,
        yellowThreshold: 30
    });

    const isLoadingForm = useSignal(false);

    useEffect(() => {
        if (data) {
            apiVersion.current = `${data.apiName} - ${data.apiVersion}`;

            form.value = {
                maxLimit: data.presenter.maxCreditLimit,
                maxLimitPercentage: data.presenter.maxCreditLimitPercent,
                isBlockedByPercent: data.presenter.validateMaxCreditLimitPercent,
                decimalPlaces: data.presenter.ruralCreditLimitDecimalPlaces,
                isCreditLimitRounded: data.presenter.isCreditLimitRounded,
                greenThreshold: data.presenter.greenThreshold,
                yellowThreshold: data.presenter.yellowThreshold,
            };

            lastupdatedBy.current = `${data.presenter.updatedBy} em ${data.presenter.lastUpdate}`;
        }
    }, [data]);

    const changeForm = (field: string, value: any) => {
        form.value = { ...form.value, [field]: value };
    }
    const cancelForm = () => {
        form.value = {
            maxLimit: data.presenter.maxCreditLimit,
            maxLimitPercentage: data.presenter.maxCreditLimitPercent,
            isBlockedByPercent: data.presenter.validateMaxCreditLimitPercent,
            decimalPlaces: data.presenter.ruralCreditLimitDecimalPlaces,
            isCreditLimitRounded: data.presenter.isCreditLimitRounded,
            greenThreshold: data.presenter.greenThreshold,
            yellowThreshold: data.presenter.yellowThreshold
        };
    }

    const confirmForm = () => {
        changeList.value = [];

        if (form.value.maxLimit !== data.presenter.maxCreditLimit) {
            const valueCurreny = currencyWithPrefix(form.value.maxLimit);
            changeList.value.push(`[Tela de pré-análise de limite de crédito] - Alterar valor do campo limite máximo para ${valueCurreny}`);

        }

        if (form.value.isBlockedByPercent !== data.presenter.validateMaxCreditLimitPercent) {
            if (form.value.isBlockedByPercent) {
                changeList.value.push("[Tela de pré-análise de limite de crédito] - Ativar bloqueio por limite máximo no campo m.c./c.r. m0");
            } else {
                changeList.value.push("[Tela de pré-análise de limite de crédito] - Desativar bloqueio por limite máximo no campo m.c./c.r. m0");
            }
        }
        if (form.value.isBlockedByPercent && form.value.maxLimitPercentage !== data.presenter.maxCreditLimitPercent) {
            changeList.value.push(`[Tela de pré-análise de limite de crédito] - Alterar valor do campo limite máximo por % para ${form.value.maxLimitPercentage}%`);
        }

        if (changeList.value.length > 0)
            isModalOpen.value = true;

        else {
            toast.info("Nenhuma configuração foi alterada");
        }
    }

    const submitForm = async () => {
        isLoadingForm.value = true;

        const token = await Auth.currentSession()
            .then((user) => user.getAccessToken()
                .getJwtToken());

        const response = await fetch(CONFIGURATION_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                maxCreditLimit: form.value.maxLimit,
                maxCreditLimitPercent: form.value.maxLimitPercentage,
                validateMaxCreditLimitPercent: form.value.isBlockedByPercent
            })
        });

        if (response.ok) {
            toast.success("Propriedades da pré-análise atualizada com sucesso!");
            navigate('/referrals/configuration');
        } else {
            const { message, errors } = await response.json();
            toast.error(message);
            errorFields.value = errors ? errors : [];
        }
        isModalOpen.value = false;
        isLoadingForm.value = false;
    }

    return (

        <PageForm
            pageTitle='Configurações da pré-análise'
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[{ label: 'Configurações - pré-análise', url: '#' }]}
        >
            {{
                controlSlot: (
                    <div className='pt-4 text-right' >
                        <button type='button'
                            className='text-black bg-gradient-to-r from-slate-200 via-slate-300 to-slate-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={cancelForm}
                            disabled={isLoading || isLoadingForm.value}
                        >
                            <div className="flex items-center">
                                Resetar
                            </div>
                        </button>

                        <button type='button'
                            disabled={isLoading || isLoadingForm.value}
                            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={confirmForm}>
                            <div className="flex items-center">
                                Atualizar
                            </div>
                        </button>
                    </div>
                ),
                formSlot: (
                    <>
                        {isLoading ? (
                            <div className="container mx-auto px-4 py-8">
                                <div className="h-4 bg-gray-300 w-64 rounded animate-pulse" />
                                <div className="h-4 bg-gray-300 w-96 rounded animate-pulse mt-1" />
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="h-64 bg-gray-300 col-span-6 rounded animate-pulse" />
                                    <div className="h-64 bg-gray-300 col-span-6 rounded animate-pulse" />
                                </div>
                            </div>
                        ) : (
                            <div className="container mx-auto px-4 py-8">

                                <h2 className="text-xl font-semibold">Pré-análise de limite de crédito</h2>
                                <span className='text-sm text-gray-400 mb-4'>Configurações da tela de aprovação / reprovação de limite de crédito</span>

                                <div className="grid grid-cols-12 gap-4">

                                    <div className='border rounded-lg mt-4 p-4 col-span-6'>
                                        <h3 className=' '>Campo de limite de aprovação:</h3>
                                        <div className="grid grid-cols-12 gap-4 mb-4">
                                            <div className="mb-4 mt-4 col-span-6">

                                                <CurrencyInput
                                                    label="Limite máximo:"
                                                    value={form.value.maxLimit}
                                                    onChange={(value) => changeForm('maxLimit', value)}
                                                    prefix="R$"
                                                    errorMessage={getErrorMessage(errorFields.value, 'maxCreditLimit')}
                                                />
                                                <p id="helper-text-max-limit" className="mt-2 text-sm text-gray-500">
                                                    Valor máximo da aprovação / aprovação manual de análise de limite de crédito
                                                </p>
                                            </div>

                                            <div className="mb-4 col-span-6">
                                                <ToggleSwitch
                                                    disabled={true}
                                                    checked={form.value.isCreditLimitRounded}
                                                    onChange={(value) => changeForm('isCreditLimitRounded', value)}
                                                />
                                                <p id="helper-text-is-rounded" className="text-sm text-gray-500">
                                                    Arredondar valores para cima, duas casas decimais
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='border rounded-lg mt-4 p-4 col-span-6'>
                                        <h3 className=' '>Campo M.C./C.R. M0:</h3>
                                        <div className="grid grid-cols-12 gap-4 mt-4">
                                            <div className="mb-4 flex items-center col-span-6 grid grid-cols-6">
                                                <ToggleSwitch
                                                    checked={form.value.isBlockedByPercent}
                                                    onChange={(value) => changeForm('isBlockedByPercent', value)}
                                                />
                                                <p id="helper-text-is-blocked-percentage" className="ml-2 text-sm text-gray-500 col-span-4">
                                                    Ativar limite máximo
                                                </p>
                                                <div className='col-span-6 mt-4'>
                                                    {form.value.isBlockedByPercent && (
                                                        <div className="ml-4">
                                                            <CurrencyInput
                                                                label="Limite máximo por %:"
                                                                value={form.value.maxLimitPercentage}
                                                                onChange={(value) => changeForm('maxLimitPercentage', value)}
                                                                prefix="%"
                                                                errorMessage={getErrorMessage(errorFields.value, 'maxCreditLimitPercent')}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mb-4 col-span-6">
                                                <SelectTextField
                                                    id='decimal-places'
                                                    name='decimalPlaces'
                                                    emptyLabel='Selecione a quantidade'
                                                    label="Casas decimais:"
                                                    items={
                                                        Array.from({ length: 9 }, (_, i) => ({ value: `${i}`, label: `${i}` }))}
                                                    value={form.value.decimalPlaces.toString()}
                                                    onChange={(value) => changeForm('decimalPlaces', value)}
                                                    errorMessage=''
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                            <div className="p-4 border rounded-lg bg-green-100">
                                                <h3 className="font-bold text-green-600">Verde</h3>
                                                <CurrencyInput
                                                    label="Até"
                                                    value={form.value.greenThreshold}
                                                    fractionDigits={0}
                                                    onChange={(value) => changeForm('greenThreshold', value)}
                                                    prefix="%"
                                                    disabled={true}
                                                />
                                                <p className='className="text-sm text-gray-500 col-span-4"'>Valores de 0 até {form.value.greenThreshold}%</p>
                                            </div>
                                            <div className="p-4 border rounded-lg bg-yellow-100">
                                                <h3 className="font-bold text-yellow-600">Amarelo</h3>
                                                <CurrencyInput
                                                    label="Acima de Verde até"
                                                    value={form.value.yellowThreshold}
                                                    fractionDigits={0}
                                                    onChange={(value) => changeForm('yellowThreshold', value)}
                                                    prefix="%"
                                                    disabled={true}
                                                />
                                                <p className='className="text-sm text-gray-500 col-span-4"'>Valores de {form.value.greenThreshold}% até {form.value.yellowThreshold}%</p>
                                            </div>
                                            <div className="p-4 border rounded-lg bg-red-100">
                                                <h3 className="font-bold text-red-600">Vermelho</h3>
                                                <p className="mt-2">Acima de {form.value.yellowThreshold}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-sm text-gray-400 mt-4">Última atualização por <b>{lastupdatedBy.current}</b></p>
                            </div>
                        )}

                        <PresentationModal
                            id="update-confirmation-modal"
                            show={isModalOpen.value}
                            onClose={() => isModalOpen.value = false}
                            header="Confirmar Atualização"
                            children={{
                                body: (
                                    <div className='p-2 text-sm font-medium'>
                                        <span >A atualização das propriedades vai alterar o comportamento do sistema.</span>
                                        <div className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                                            <svg className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                            </svg>
                                            <span className="sr-only">Info</span>
                                            <div>
                                                <span className="font-medium">Abaixo está a lista de mudanças que será aplicada:</span>
                                                <ul className="mt-1.5 list-disc list-inside">
                                                    {changeList.value.map((change, index) => (
                                                        <li key={index}>{change}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="ms-3 mt-4 text-base font-medium">
                                            Deseja realmente atualizar as propriedades da pré-análise de crédito?
                                        </div>
                                    </div>

                                ),
                                footer: (
                                    <div className="flex justify-end gap-4">
                                        <button
                                            className="px-4 py-2 bg-gray-400 rounded text-white"
                                            onClick={() => isModalOpen.value = false}
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            className="px-4 py-2 bg-blue-600 rounded text-white"
                                            onClick={submitForm}
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                ),
                            }}
                        />
                    </>
                )
            }}
        </PageForm>
    );
};

export default ReferralConfiguration;