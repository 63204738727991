import { ErrorField } from '../types/ErrorField';

export const getErrorMessage = (errorFields: ErrorField[], fieldName: string): string => {
    const error = errorFields.find((error) => error.fieldName === fieldName);
    return error ? error.message : '';
};

export const removeErrorField = (errorFields: ErrorField[], fieldName: string): void => {
    const idx = errorFields.findIndex((error) => error.fieldName === fieldName);
    if (idx !== -1) {
        errorFields.splice(idx, 1);
    }
};

export const hasErrorMessage = (errorFields: ErrorField[], fieldName: string): boolean => {
    return getErrorMessage(errorFields, fieldName) !== '';
};

export const getErrorMessageAsLabels = (errorFields: ErrorField[], fieldName: string): { label: string }[] => {
    const errorMessage = getErrorMessage(errorFields, fieldName);

    if (!errorMessage) return [];

    return errorMessage.split(',').map((item) => ({ label: item.trim() }));
};