import { Outlet } from "react-router-dom";
import Loading from "../ions/Loading";
import PageHeader from "../molecules/PageHeader";
import { ReactNode } from "react";

interface BreadcrumbItem {
    label: string;
    url: string;
}

type PageFormProps = {
    pageTitle: string;
    apiVersion: string;
    isLoading: boolean;
    breadcrumb: BreadcrumbItem[];
    children: {
        controlSlot: ReactNode;
        formSlot: ReactNode;
    }
}

const PageForm = ({ pageTitle, isLoading, breadcrumb,
    apiVersion,
    children }: PageFormProps) => {

    const { controlSlot, formSlot } = children;

    return (
        <div className='px-4'>
            {isLoading && <Loading />}
            <PageHeader title={pageTitle}
                apiVersion={apiVersion}
                breadcrumb={breadcrumb} >
                <div className='pt-4 w-full'>
                    {controlSlot}
                </div>
            </PageHeader>

            {formSlot}

            <Outlet />
        </div>
    );
};

export default PageForm;