import { ReactNode, FC } from "react";
import Breadcrumb from "../atoms/Breadcrumb";
import PageController from "./PageController";

type PageHeaderProps = {
    title: string;
    breadcrumb: { label: string, url: string }[];
    apiVersion: string;
    children: ReactNode;
}

const PageHeader: FC<PageHeaderProps> = ({ title, apiVersion, breadcrumb, children }) => {

    const items = [{ label: 'Home', url: '/home' }].concat(breadcrumb);

    return (
        <div className="grid grid-cols-2 gap-2">
            <div className="sticky top-0 z-10">
                <Breadcrumb items={items} />
                <h1 className="inline-block text-2xl text-teal-500 font-medium tracking-tight pt-2 pb-3">
                    {title}
                </h1>

            </div>

            <PageController>
                <span className="absolute text-xs text-slate-400 mr-2">{apiVersion}</span>
                {children}
            </PageController>
        </div>
    );
};

export default PageHeader;