import { FC, Fragment, useRef } from "react";
import TextEdit from "./TextEdit";
import { IoCloudUploadOutline } from 'react-icons/io5';

type FileUploadProps = {
    id?: string;
    name: string;
    accept: string;
    label: string;
    canRemove?: boolean;
    errorMessage: string;
    info?: string;
    onUploadFile: (file: File) => void;
    onRemoveFile?: () => void;
    disabled?: boolean;
}

const FileUpload: FC<FileUploadProps> = ({ onUploadFile, onRemoveFile = () => { }, accept = "*",
    canRemove = true, errorMessage, info, label, id = "dropfile", name, disabled = false }) => {

    const fileRef = useRef<File | null>(null);
    const dragActiveRef = useRef<boolean>(false);

    const error = errorMessage ? true : false;
    const borderColor = error ? "border-red-600" : "border-gray-300";

    function handleDrop(e: any) {
        e.preventDefault();
        e.stopPropagation();
        dragActiveRef.current = false;
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            fileRef.current = e.dataTransfer.files[0];
            onUploadFile(e.dataTransfer.files[0]);
        }
    }

    function handleDragLeave(e: any) {
        e.preventDefault();
        e.stopPropagation();
        dragActiveRef.current = false;
    }

    function handleDragOver(e: any) {
        e.preventDefault();
        e.stopPropagation();
        dragActiveRef.current = true;
    }

    function handleDragEnter(e: any) {
        e.preventDefault();
        e.stopPropagation();
        dragActiveRef.current = true;
    }

    const changeFile = (e: any) => {
        const file = e.target.files[0];
        fileRef.current = file;
        onUploadFile(file);
    }

    const removeFile = () => {
        fileRef.current = null;
        onRemoveFile();
    }

    return (
        <Fragment>
            {fileRef.current ?
                <div className="grid grid-cols-12 gap-4">
                    <div className='col-span-11'>
                        <TextEdit label={label}
                            className='col-span-12'
                            value={fileRef.current.name}
                            errorMessage={errorMessage}
                            disabled
                        />
                    </div>
                    {canRemove ?
                        <button
                            id='remove-file'
                            type='button'
                            className="text-red-500 cursor-pointer col-span-1 disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => removeFile()}
                            disabled={disabled}
                        >
                            Remover
                        </button> : null
                    }
                </div>
                :
                <Fragment>
                    <div className="flex items-center justify-center w-full" >
                        <label htmlFor={id}
                            className={`${dragActiveRef.current ? "border-blue-600" : borderColor} 
                        flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100`}
                            onDragEnter={handleDragEnter}
                            onSubmit={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                            onDragLeave={handleDragLeave}
                            onDragOver={handleDragOver}
                        >
                            <div
                                className="flex flex-col items-center justify-center pt-5 pb-6"
                            >
                                <IoCloudUploadOutline className="text-4xl text-gray-500 " />
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">Clique aqui para subir arquivo</span> ou arraste e solte aqui.
                                </p>
                                {info ? <p className="text-xs text-gray-500 dark:text-gray-400">{info}</p> : null}
                            </div>
                            <input id={id} type="file" className="hidden"
                                disabled={disabled}
                                name={name}
                                accept={accept}
                                onChange={changeFile} />
                        </label>

                    </div>
                    {error
                        ? <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
                        : null}
                </Fragment>
            }

        </Fragment>
    );

};

export default FileUpload;