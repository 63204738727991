import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children, roles }: { children: JSX.Element, roles: string[] }) => {

    const auth = useContext(AuthContext);

    if (!auth.user) {
        return <Navigate to="/login" replace />
    }

    if (auth.user.roles.reduce((result, role) => roles.includes(role) || result, false) === false) {
        return <Navigate to="/denied" replace />
    }

    return children;
}

export default RequireAuth;