import { FC, Fragment } from "react";

type ProgressBarProps = {
    label?: string
    progress: number
    visible?: boolean
}

const ProgressBar: FC<ProgressBarProps> = ({ label, progress, visible = true }) => {

    const progressLabel = progress + "%";

    return (
        <Fragment>
            {visible ?
                <Fragment>
                    <div className="flex justify-between mb-1">
                        <span className="text-base font-medium text-blue-700 dark:text-white">
                            {label}
                        </span>
                        <span className="text-sm font-medium text-blue-700 dark:text-white">
                            {progressLabel}
                        </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: progressLabel }}></div>
                    </div>
                </Fragment>
                : null}
        </Fragment>
    );
}

export default ProgressBar;