import { FC } from "react";

interface InternalErrorImageProps {
	className?: string;
}

const InternalErrorImage:FC<InternalErrorImageProps> = ({ className }) => {
    return (
      <img src="/internal-error.png"
          className={`flex items-center ${className}`} alt="Imagem erro interno" />
    );
};

export default InternalErrorImage;