import React, { FC, ReactElement, useState } from 'react';
import { IconType } from 'react-icons';

interface MultiFloatingButtonProps {
  mainButtonColor: string;
  mainButtonIcon: IconType;
  mainButtonHint?: string;
  actionButtons: ActionButton[];
}

interface ActionButton {
  color: string;
  icon: IconType;
  hint?: string;
  onClick: () => void;
}

const MultiFloatingButton: FC<MultiFloatingButtonProps> = ({
  mainButtonColor,
  mainButtonIcon,
  mainButtonHint,
  actionButtons,
}) => {
  const [showActions, setShowActions] = useState(false);

  const toggleActions = () => {
    setShowActions(!showActions);
  };

  const mainButtonIconElement: ReactElement = React.createElement(mainButtonIcon);

  return (
    <div className="relative flex flex-col items-end">
      <div className="fixed bottom-8 right-8">
        <button
          className={`bg-${mainButtonColor}-400 hover:bg-${mainButtonColor}-600 text-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg hover:shadow-gray-600 transition duration-300`}
          onClick={toggleActions}
        >
          {mainButtonIconElement}
        </button>
      </div>
      {showActions && (
        <div className="fixed bottom-20 mb-3 right-9 space-y-2">
          {actionButtons.map((action, index) => (
            <button
              key={index}
              className={`bg-${action.color}-400 hover:bg-${action.color}-600 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg hover:shadow-gray-600 transition duration-300`}
              onClick={() => {
                toggleActions();
                action.onClick();
              }}
            >
              {React.createElement(action.icon)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiFloatingButton;
