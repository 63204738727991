import React, { useState, ReactNode } from 'react';

interface TooltipProps {
    children: ReactNode;
    content: ReactNode;
    alignment?: 'left' | 'right' | 'top' | 'bottom';
}

const NewTooltip: React.FC<TooltipProps> = ({ children, content, alignment = 'top' }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const handleMouseEnter = () => setIsTooltipVisible(true);
    const handleMouseLeave = () => setIsTooltipVisible(false);

    // Define dynamic positioning based on alignment prop
    const getAlignmentStyles = () => {
        switch (alignment) {
            case 'top':
                return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
            case 'bottom':
                return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
            case 'left':
                return 'right-full top-1/2 transform -translate-y-1/2 mr-2';
            case 'right':
                return 'left-full top-1/2 transform -translate-y-1/2 ml-2';
            default:
                return 'top-full left-1/2 transform -translate-x-1/2 mt-2'; // Default to bottom
        }
    };

    return (
        <span
            className="relative inline-block"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {isTooltipVisible && (
                <div
                    className={`absolute z-10 ${getAlignmentStyles()}`}
                    role="tooltip"
                >
                    <div className="tooltip-content bg-gray-900 text-white text-sm px-3 py-2 rounded shadow-lg dark:bg-gray-700">
                        {content}
                    </div>
                    <div
                        className={`tooltip-arrow w-2 h-2 bg-gray-900 transform rotate-45 dark:bg-gray-700 ${alignment === 'top'
                            ? 'absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2'
                            : alignment === 'bottom'
                                ? 'absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                                : alignment === 'left'
                                    ? 'absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
                                    : 'absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
                            }`}
                    ></div>
                </div>
            )}
        </span>
    );
};

export default NewTooltip;
