export const currencyWithPrefix = (value: number) => {
    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

    return formattedValue;
};

export const concurrencyWithoutPrefix = (value: number) => {
    const formattedValue = currencyWithPrefix(value);
    return formattedValue.replace(/^R\$\s?/, '');
}