import React, { useState, KeyboardEvent, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';

interface PageSearchProps {
	value: string;
	disabled?: boolean;
	label?: string;
	onSearch: (query: string) => void;
	filterCleanVisible: boolean;
	configureVisible: boolean;
	filterCleanEnabled?: boolean;
	onFilterClean?: () => void;
	onConfigureClick?: () => void;
	className?: string;
}

const PageSearch: React.FC<PageSearchProps> = ({
	value,
	disabled,
	label = "Procurar por...",
	className,
	onSearch,
	onFilterClean = () => { },
	filterCleanEnabled = false,
	onConfigureClick = () => { },
	filterCleanVisible,
	configureVisible
}) => {
	const [searchQuery, setSearchQuery] = useState(value);

	useEffect(() => {
		setSearchQuery(value);
	}, [value]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearch(searchQuery);
		}
	};

	return (
		<div className={`relative w-full mb-1 ${className}`}>
			<input
				id="floating_outlined"
				type="text"
				className="block py-2.5 px-10 w-full text-sm text-gray-900 bg-white border border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-600 peer appearance-none pl-8"
				placeholder=" "
				value={searchQuery}
				onChange={handleInputChange}
				onKeyUp={handleKeyUp}
				disabled={disabled}
			/>

			<label htmlFor="floating_outlined"
				className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 left-8 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:-translate-x-4">
				{label}
			</label>

			<div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
				<AiOutlineSearch className="text-gray-500 h-6 w-6" />
			</div>

			<div className="absolute inset-y-0 right-3 flex items-center">
				{filterCleanVisible && (
					<button onClick={onFilterClean} disabled={!filterCleanEnabled} className="ml-2">
						<FaFilterCircleXmark className={`${filterCleanEnabled ? 'text-gray-500' : 'text-gray-200'} h-6 w-6`} />
					</button>
				)}

				{configureVisible && (
					<button onClick={onConfigureClick} className="ml-2">
						<HiOutlineCog6Tooth className="text-gray-500 h-6 w-6" />
					</button>
				)}
			</div>
		</div>
	);
};

export default PageSearch;
