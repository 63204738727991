import { FC, ChangeEvent } from 'react';

interface Option {
    value: string;
    label: string;
}

type SelectFilterProps = {
    id: string,
    value: any;
    label: string;
    onChange: (value: string) => void;
    defaultVoidOption?: boolean;
    defaultLabel?: string;
    options: Option[];
    className?: string;
}

const SelectFilter: FC<SelectFilterProps> = ({ id, value, label,
    onChange, className,
    defaultVoidOption, options,
    defaultLabel = "Todas..." }) => {

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        onChange(event.target.value);
    };

    return (
        <div className={`relative ${className}`}>
            <select
                id={id}
                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-white border border-gray-200 mb-1 focus:outline-none bg-white w-full appearance-none"
                value={value}
                onChange={handleChange}
            >
                {defaultVoidOption && <option value="">{defaultLabel}</option>}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>

            <label htmlFor={id}
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                {label}
            </label>
        </div>
    );
}

export default SelectFilter;