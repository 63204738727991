import { FC } from "react";
import { FaExchangeAlt, FaUndo } from "react-icons/fa";
import Tooltip from "../atoms/Tooltip";


type AdditionalContextProps = {
    text: string,
}

const ReferralAdditionalContext: FC<AdditionalContextProps> = ({ text }) => {

    switch (text) {

        case "RP":
            return (
                <Tooltip content={"Revolvência positiva"}>
                    <FaUndo style={{ color: 'cornflowerblue' }} />
                </Tooltip>
            );
        case "RN":
            return (
                <Tooltip content={"Revolvência negativa"}>
                    <FaUndo style={{ color: 'rgb(242, 5, 37)' }} />
                </Tooltip>
            );
        case "T":
            return (
                <Tooltip content={"Troca de CPF"}>
                    <FaExchangeAlt style={{ color: 'goldenrod' }} />
                </Tooltip>
            );
        case "":
            return (
                <span>-</span>
            );
        default:
            return (
                <Tooltip content={text}>
                    <span>{text}</span>;
                </Tooltip>
            );
    }

}

export default ReferralAdditionalContext;