import { FC } from "react";

interface NetworkErrorImageProps {
    className?: string;
  }


const NetworkErrorImage:FC<NetworkErrorImageProps> = ({ className }) => {
    return (
            <img src="/network-error.png"
                className={`flex items-center ${className}`} alt="Erro de conexão" />
    );
};

export default NetworkErrorImage;