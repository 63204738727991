import React from 'react';

interface ToggleSwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    className?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ checked, onChange, disabled = false, className = '' }) => {
    const handleToggle = () => {
        if (!disabled) {
            onChange(!checked);
        }
    };

    return (
        <div
            role="switch"
            aria-checked={checked}
            onClick={handleToggle}
            className={`relative inline-flex items-center w-10 h-5 rounded-full transition-colors duration-300 ${checked ? 'bg-blue-500' : 'bg-gray-400'
                } ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} ${className}`}
        >
            <span
                className={`absolute w-4 h-4 bg-white rounded-full shadow transform transition-transform duration-300 ${checked ? 'translate-x-5' : 'translate-x-1'
                    }`}
            ></span>
            <input
                type="checkbox"
                className="hidden"
                checked={checked}
                onChange={handleToggle}
                disabled={disabled}
            />
        </div>
    );
};

export default ToggleSwitch;
