import React, { ReactNode } from 'react';

interface InputFrameProps {
    label: string;
    errorMessage?: string;
    className?: string;
    children: ReactNode;
    prefix?: string;
    textColor?: string;
}

const InputFrame: React.FC<InputFrameProps> = ({ label, errorMessage, className, children, prefix, textColor = "text-gray-500" }) => {
    let peerFocusColor = "peer-focus:text-blue-600";

    if (errorMessage) {
        peerFocusColor = "peer-focus:text-red-600";
    }

    return (
        <div className={`relative ${className}`}>
            <div className="flex items-center">
                {prefix && <span className={`mr-2 text-sm ${textColor}`}>{prefix}</span>}
                {children}
            </div>
            <label
                className={`absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 ${peerFocusColor} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
            >
                {label}
            </label>
            {errorMessage && <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p>}
        </div>
    );
};

export default InputFrame;
