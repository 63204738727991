import { FC } from "react";
import { FaHandshake, FaUser } from "react-icons/fa";
import Tooltip from "../atoms/Tooltip";


type ReferralOriginProps = {
    id: number,
    title: string,
    environment: string;
}

const ReferralOriginIcon: FC<ReferralOriginProps> = ({ id, title, environment }) => {

    if (environment === "external")
        return (
            <Tooltip content={title}>
                <FaHandshake style={{ color: "cornflowerblue" }} />
            </Tooltip>
        );

    return (
        <Tooltip content={title}>
            <FaUser style={{ color: "cornflowerblue" }} />
        </Tooltip>
    );

}

export default ReferralOriginIcon;