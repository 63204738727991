import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "../../ions/Loading";
import { Fragment, useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import PageHeader from "../../molecules/PageHeader";
import TableLoading from "../../ions/TableLoading";
import Tab from "../../molecules/Tab";

import { BiTrash } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import TextEdit from "../../molecules/TextEdit";

const PartnerViewPage = () => {

    const { id } = useParams();

    const tabItems = [
        { id: "tab-1", title: 'Dados cadastrais', index: 0 },
        { id: "tab-2", title: 'Comercial', index: 1 },
        { id: "tab-3", title: 'Contas bancárias', index: 2 },
        { id: "tab-4", title: 'Signatários', index: 3 },
        { id: "tab-5", title: 'Avalistas', index: 4 },
        { id: "tab-6", title: 'Crédito', index: 5 },
        { id: "tab-7", title: 'Integração Jira', index: 6 },
    ];


    const breadcrumb = [
        { label: "Parceiras", url: "/partners" },
        { label: `Parceira #${id}`, url: "#" }
    ];

    const [searchParams] = useSearchParams();
    const tabIndex = parseInt(searchParams.get('tab') || "0");

    const token = useRef('');
    const apiVersion = useRef('');

    const [isLoading, setIsLoading] = useState(true);
    const BASE_URL = process.env.REACT_APP_PARTNER_API_URL;

    const partner = useRef({
        id: '',
        uuid: '',
        active: false,
        alias: '',
        status: '',
        statusId: 0,
        govTaxId: '',
        legalName: '',
        fantasyName: '',

        fullLink: '',
        type: '',
        site: '',
        logo: '',
        addressOne: '',
        addressNumber: '',
        addressTwo: '',
        addressDetail: '',
        postalCode: '',
        cityProvince: '',

        guaranteePercentage: '',

        contactName: '',
        contactPhone: '',
        contactEmail: '',

        financialName: '',
        financialPhone: '',
        financialEmail: '',

        createdAt: '',
        modifiedAt: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentSession()
            .then((user) => user.getAccessToken().getJwtToken())
            .then((tkn) => {
                token.current = tkn;
                return tkn;
            })
            .then((token) => fetchData(token))
            .catch((error) => {
                navigate("/denied");
                console.error('Error during authentication:', error);
            });
    }, []);

    const fetchData = (token: string) => {
        fetch(`${BASE_URL}/page/partner-view/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors'
        })
            .then(response => response.json())
            .then(data => {
                //TODO corrigir, chamando 2 vezes
                console.log(data);
                apiVersion.current = `${data.apiName} - ${data.apiVersion}`;
                partner.current = data.resource;
                setIsLoading(false);
            }).catch(() => navigate('/network-error'))
    }

    return (
        <div className='px-4'>
            {isLoading && <Loading />}
            <PageHeader
                title={partner.current.alias.toUpperCase()}
                breadcrumb={breadcrumb}
                apiVersion={apiVersion.current}
            >
                <div className='p-4'>
                    <button className="p-2 text-sm font-medium text-white rounded-lg text-center inline-flex items-center bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800 mr-2">
                        <FiEdit2 className="mr-2" />
                        Alterar
                    </button>
                    <button className="p-2 text-sm font-medium text-white rounded-lg text-center inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                        <BiTrash className="mr-2" />
                        Remover
                    </button>
                </div>
            </PageHeader>
            {isLoading ? <TableLoading /> :
                <Fragment>
                    <Tab tabItems={tabItems} tabIndex={tabIndex}></Tab>
                    <div id="tab-content" className="overflow-y-auto">
                        {tabIndex == 0 &&
                            <div className="p-4 m-4"
                                id="profile">
                                <div className="grid grid-cols-12 gap-4">

                                    <div className="col-span-2">
                                        <TextEdit label="Status:" value={partner.current.status} disabled />
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="Tipo:" value={partner.current.type} disabled />
                                    </div>

                                    <div className="col-span-3">
                                        <TextEdit label="Site:" value={partner.current.site} disabled />
                                    </div>

                                    <div className="col-span-2" />

                                    <div className="col-span-3 flex flex-col">
                                        <span className="text-sm">Data/hora de criação:
                                            <b>{partner.current.createdAt}</b>
                                        </span>
                                        <span className="text-sm">Data/hora de atualização:
                                            <b>{partner.current.modifiedAt}</b>
                                        </span>
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="CNPJ:" value={partner.current.govTaxId} disabled />
                                    </div>

                                    <div className="col-span-5">
                                        <TextEdit label="Nome fantasia:" value={partner.current.fantasyName} disabled />
                                    </div>

                                    <div className="col-span-5">
                                        <TextEdit label="Razão social:" value={partner.current.legalName} disabled />
                                    </div>

                                    <h2 className="mb-2 text-base text-gray-700 font-medium col-span-12">Endereço</h2>

                                    <div className="col-span-6">
                                        <TextEdit label="Rua:" value={partner.current.addressOne} disabled />
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="Número:" value={partner.current.addressNumber} disabled />
                                    </div>

                                    <div className="col-span-4">
                                        <TextEdit label="Bairro:" value={partner.current.addressTwo} disabled />
                                    </div>

                                    <div className="col-span-4">
                                        <TextEdit label="Complemento:" value={partner.current.addressDetail} disabled />
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="CEP:" value={partner.current.postalCode} disabled />
                                    </div>

                                    <div className="col-span-6">
                                        <TextEdit label="Cidade/UF:" value={partner.current.cityProvince} disabled />
                                    </div>

                                    <h2 className="mb-2 text-base text-gray-700 font-medium col-span-12">Contato direto</h2>

                                    <div className="col-span-5">
                                        <TextEdit label="Nome:" value={partner.current.contactName} disabled />
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="Telefone:" value={partner.current.contactPhone} disabled />
                                    </div>

                                    <div className="col-span-5">
                                        <TextEdit label="Email:" value={partner.current.contactEmail} disabled />
                                    </div>

                                    <h2 className="mb-2 text-base text-base-700 font-medium col-span-12">Contato financeiro</h2>

                                    <div className="col-span-5">
                                        <TextEdit label="Nome:" value={partner.current.financialName} disabled />
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="Telefone:" value={partner.current.financialPhone} disabled />
                                    </div>

                                    <div className="col-span-5">
                                        <TextEdit label="Email:" value={partner.current.financialEmail} disabled />
                                    </div>

                                </div>

                            </div>}

                        {tabIndex == 1 &&
                            <div className="p-4 m-4"
                                id="profile">
                                <div className="grid grid-cols-12 gap-4">

                                    <div className="col-span-4 row-span-6">

                                        <figure className="max-w-lg">
                                            <img className="h-auto max-w-full rounded-lg" src={partner.current.logo}
                                                alt="Logo da parceira" />
                                            <figcaption className="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
                                                Logo da parceira
                                            </figcaption>
                                        </figure>

                                    </div>

                                    <div className="col-span-5">
                                        <TextEdit label="Link de empréstimo:" value={partner.current.fullLink} disabled />
                                    </div>

                                    <div className="col-span-3" />

                                    <div className="col-span-2">
                                        <TextEdit label="Alias:" value={partner.current.alias} disabled />
                                    </div>

                                    <div className="col-span-2">
                                        <TextEdit label="% de aval:" value={partner.current.guaranteePercentage} disabled />
                                    </div>

                                    <div className="col-span-7" />

                                </div>
                            </div>}
                    </div>

                </Fragment>
            }
        </div>
    );
};


export default PartnerViewPage;