import { FC } from "react";

interface NotFoundImageProps {
    className?: string;
  }


const NotFoundImage:FC<NotFoundImageProps> = ({ className }) => {
    return (
            <img src="/not-found.png"
                className={`flex items-center ${className}`} alt="Imagem página não encontrada" />
    );
};

export default NotFoundImage;