import { useContext, useEffect } from 'react';
import { AuthContext } from '../../services/Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

	const navigate = useNavigate();
	const auth = useContext(AuthContext);

	useEffect(() => {
		const logout = async () => {
			await auth.signout();
			navigate("/login");
		}
		logout();
	}, []);

	return (
		<div className="flex flex-col items-center justify-center h-screen bg-white">
			<div className="bg-neutral-50 rounded-lg shadow-lg p-4 flex items-center justify-center">
				<div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
				<p className="mb-2">Aguarde, realizando logout da aplicação...</p>
			</div>
		</div>
	);
};

export default Logout;