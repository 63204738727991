import { FC } from 'react';

interface BadgePartnerStatusProps {
  statusId: number;
  status: string;
}

const BadgePartnerStatus: FC<BadgePartnerStatusProps> = ({ statusId, status }) => {
  const colorClass: { [key: number]: string } = {
    1: 'bg-blue-500 text-white',
    2: 'bg-blue-400 text-white',
    3: 'bg-gray-700 text-white',
    4: 'bg-green-500 text-white',
    5: 'bg-red-500 text-white'
  };

  const badgeClass = `h-6 px-2 py-1 inline-flex items-center rounded-lg text-xs font-light ${colorClass[statusId]}`;

  return (
    <span className={badgeClass}>
      {status}
    </span>
  );
};

export default BadgePartnerStatus;