import { FC, ReactElement } from "react";

import { FaExclamationTriangle, FaHourglassHalf, FaSpinner, FaThumbsDown, FaThumbsUp, FaTimes } from "react-icons/fa";
import NewTooltip from "../atoms/NewTooltip";

type AnalysisRecommendationProps = {
    code: string;
    description: string;
    className?: string;
};

const AnalysisRecommendationIcon: FC<AnalysisRecommendationProps> = ({ code, description, className }) => {
    const icons: Record<string, ReactElement> = {
        "approved": <FaThumbsUp {...{ style: { color: "cornflowerblue" }, className, "data-testid": "icon-approved" }} />,
        "not-approved": <FaThumbsDown {...{ style: { color: "rgb(242, 5, 37)" }, className, "data-testid": "icon-not-approved" }} />,
        "pending": <FaExclamationTriangle {...{ style: { color: "goldenrod" }, className, "data-testid": "icon-pending" }} />,
        "reanalysis": <FaSpinner {...{ style: { color: "cornflowerblue" }, className, "data-testid": "icon-reanalysis" }} />,
        "processing": <FaHourglassHalf {...{ style: { color: "goldenrod" }, className, "data-testid": "icon-processing" }} />,
        "error": <FaTimes {...{ style: { color: "rgb(242, 5, 37)" }, className, "data-testid": "icon-error" }} />
    };

    return (
        <NewTooltip content={description}>
            {icons[code]}
        </NewTooltip>
    );
};

export default AnalysisRecommendationIcon;
