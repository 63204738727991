import { FC } from "react";
import Tooltip from "../atoms/Tooltip";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

type ManualAnalysisStateProps = {
    isApproved: boolean;
}

const ManualAnalysisStateIcon: FC<ManualAnalysisStateProps> = ({ isApproved }) => {

    const description = isApproved ? "Aprovado" : "Reprovado";

    const icon = isApproved ? <FaThumbsUp style={{ color: "cornflowerblue" }} />
        : <FaThumbsDown style={{ color: "rgb(242, 5, 37)" }} />

    return (
        <Tooltip content={description}>
            {icon}
        </Tooltip>
    );
}

export default ManualAnalysisStateIcon;