import Tooltip from '../../atoms/Tooltip';
import PageSearch from '../../molecules/PageSearch';
import { useNavigate, useParams } from 'react-router-dom';

import PageList from '../../templates/PageList';

const FEATURE_FLAG_LIST_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/feature-flags/page-list`;

type FeatureFlag = {
    id: string;
    name: string;
    description: string;
    status: string;
    active: boolean;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
};

const FeatureFlagReferralListPage = () => {

    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted, order: orderSorted, filter }: any = useParams();

    let txtFlagName = '';

    if (filter) {
        [txtFlagName = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));
    }

    const url = FEATURE_FLAG_LIST_URL + `?name=${txtFlagName}&page=${page}&pageSize=${pageSize}`;

    const getCssColorBy = (active: boolean | undefined) => {
        return active ? 'bg-green-500' : 'bg-red-500';
    };

    const renderRows = (rows: FeatureFlag[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={6} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            No feature flags found...
                        </div>
                    </td>
                </tr>
            );
        }

        return rows.map((flag: FeatureFlag, index: number) => (
            <tr
                key={flag.id}
                onClick={() => navigate(`/feature-flags/referral/view/${flag.id}`)}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border-gray-300 cursor-pointer`}
            >
                <td className="py-2 px-2">{flag.name}</td>
                <td className="py-2 px-2">
                    <Tooltip content={flag.description} alignment='bottom'>
                        {flag.description}
                    </Tooltip>
                </td>
                <td className="py-2 px-2">{flag.updatedBy}</td>
                <td className="py-2 px-2">{flag.updatedAt}</td>
                <td className="py-2 px-2" align="center">
                    <span className={`rounded px-1 py-1 text-xs font-bold text-white ${getCssColorBy(flag.active)}`}>
                        {flag.status}
                    </span>
                </td>
            </tr>
        ));
    };

    const pageChanged = (page: number) => {
        navigate(urlNavigate(page - 1, pageSize, fieldSorted, orderSorted, txtFlagName), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFlagName), { replace: true });
    };

    const pageSorted = (field: keyof FeatureFlag | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtFlagName), { replace: true });
    };

    const txtFlagNameChange = (name: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, name), { replace: true });
    };

    const onFilterClean = () => {
        txtFlagName = '';
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, ''), { replace: true });
    };

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        name: string) => {
        return `/feature-flags/referral/${page}/${pageSize}/${fieldSorted}/${orderSorted}/n=${name};`;
    };

    const newFeatureFlagAction = () => {
        navigate("/feature-flags/new");
    };

    return (
        <PageList
            pageTitle="Feature flags (Referral)"
            url={url}
            breadcrumb={[{ label: 'Feature flags', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel="flags"
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-12 gap-1">
                        <PageSearch
                            value={txtFlagName}
                            label="Procupar por nome ou descrição"
                            onSearch={txtFlagNameChange}
                            filterCleanVisible={true}
                            filterCleanEnabled={Boolean((txtFlagName))}
                            onFilterClean={onFilterClean}
                            configureVisible={false}
                            className="col-span-12"
                        />
                    </div>
                ),
                controlSlot: (
                    <></>
                )
            }}
        </PageList>
    );
};

export default FeatureFlagReferralListPage;
