import { FC, Fragment } from "react";
import Tooltip from "./Tooltip";

export enum ErrorItemType {
    SUCCESS = 'success',
    ERROR = 'error',
}

type SpanItemProps = {
    label: string;
    tooltip?: string;
    link?: string;
}


type SpanListProps = {
    label?: string;
    items: SpanItemProps[] | undefined;
    itemType: ErrorItemType;
}

const SpanList: FC<SpanListProps> = ({ label, items = [], itemType }) => {

    const classSuccess = 'bg-green-100 hover:bg-green-300 text-green-800 border-green-400';
    const classError = 'bg-pink-100 hover:bg-red-300 text-pink-800 border-pink-400';

    return (
        <Fragment>
            {label ?
                <div className='block text-sm font-medium text-red-700 mb-1'>
                    {label}:
                </div> : null}
            <div className="flex flex-auto flex-row flex-wrap">
                {items?.map((item) => (
                    <Fragment>
                        {item.tooltip ?
                            <Tooltip content={item.tooltip} alignment="bottom">
                                <span
                                    key={Math.random().toString()}
                                    className={`mb-2 text-xs font-medium me-2 px-2.5 py-0.5 rounded border ${itemType == ErrorItemType.SUCCESS ? classSuccess : classError}`}>
                                    {item.label}
                                </span>
                            </Tooltip>
                            :
                            <span
                                key={Math.random().toString()}
                                className={`mb-2 text-xs font-medium me-2 px-2.5 py-0.5 rounded border ${itemType == ErrorItemType.SUCCESS ? classSuccess : classError}`}>
                                {item.label}
                            </span>
                        }
                    </Fragment>)
                )}
            </div>
        </Fragment>
    );
}

export default SpanList;
