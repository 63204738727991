import { FC } from "react";

interface AccessDeniedProps {
    className?: string;
  }


const AccessDeniedImage:FC<AccessDeniedProps> = ({ className }) => {
    return (
      <img src="/access-denied.png"
        className={`flex items-center ${className}`} 
        alt="Imagem acesso negado" />
    );
};

export default AccessDeniedImage;