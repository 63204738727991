import React, { useEffect, useState } from 'react';

interface AAlertProps {
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
    onClose: () => void;
}

const AAlert: React.FC<AAlertProps> = ({ message, type, onClose }) => {
    const [fadeOut, setFadeOut] = useState(false);

    const getAlertClasses = () => {
        switch (type) {
            case 'success':
                return 'bg-green-100 border-green-400 text-green-700';
            case 'error':
                return 'bg-red-100 border-red-400 text-red-700';
            case 'warning':
                return 'bg-yellow-100 border-yellow-400 text-yellow-700';
            case 'info':
                return 'bg-blue-100 border-blue-400 text-blue-700';
            default:
                return 'bg-gray-100 border-gray-400 text-gray-700';
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setFadeOut(true), 5000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (fadeOut) {
            const fadeTimer = setTimeout(onClose, 300); // 300ms to match the transition duration
            return () => clearTimeout(fadeTimer);
        }
    }, [fadeOut, onClose]);

    return (
        <div
            className={`border-t-4 rounded-b px-4 py-3 shadow-md mb-4 transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'
                } ${getAlertClasses()}`}
            role="alert"
        >
            <div className="flex justify-between items-center">
                <span className="block sm:inline">{message}</span>
                <button
                    onClick={onClose}
                    className="text-xl font-bold ml-4 cursor-pointer focus:outline-none"
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default AAlert;
