const BackofficeLogo = () => {

    return (
        <div className="flex items-center pl-2.5 mb-5">
            <img src="/backoffice-icon.png"
                className="h-6 mr-2 sm:h-10" alt="Backoffice Ícone" />
        </div>
    );
};

export default BackofficeLogo;