import { useEffect, useRef, useState } from 'react';

import { Auth } from 'aws-amplify';
import BadgePartnerStatus from '../../atoms/BadgePartnerStatus';
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';


function PartnerFormPage()  {
    
    const token = useRef('');
    const [form, setForm] = useState({
        id: null,
        govTaxId: '',
        fantasyName: '',
        legalName: '',
        guaranteePercentage: 0,
        addressOne: '',
        addressnumber: '',
        addressTwo: '',
        addressDetail: '',
        addressCityId: null,
        email: '',
        phone: '',
        
    });
   
    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentSession()
            .then((user) => user.getAccessToken().getJwtToken())
            .then((tkn) => { 
                token.current = tkn;
                return tkn;
            })
            .then((token) => fetchPage(token))
            .catch((error) => {
                navigate("/denied");
                console.error('Error during authentication:', error);
            });
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

     const fetchPage = async (token: string) => {
    //     const response = await apiGet(`${BASE_URL}/page/partners`);

    //     if(response) {
    //         const data = await response.json();
    //         setFieldSorted(data.sortedColumn.field);
    //         setOrderSorted(data.sortedColumn.order);
    //         size.current = data.pageSize;
    //         apiVersion.current = `${data.apiName} - ${data.apiVersion}`;
    //         columns.current = data.headers;
            
    //         await fetchData('', data.sortedColumn.field, data.sortedColumn.order, 0);
    //     }
     }

    // const fetchData = async (search: string, field: string, order: 'ASC' | 'DESC', page: number) => {
    //     search = search.replace(/[^a-zA-Z0-9]/g, '');
    //     const url = BASE_URL + `/partners?search=${search}`
    //     + `&page=${page}&size=${size.current}&sort=${field},${order}`;

    //     const response = await apiGet(url);
    //     if(response) {
    //         const data = await response.json();
    //         size.current = (parseInt(response.headers.get('size') || '0'));
    //         totalPages.current = (parseInt(response.headers.get('totalPages') || '0'));
    //         totalElements.current = (parseInt(response.headers.get('totalElements') || '0'));
    //         setPage(parseInt(response.headers.get('page') || '0'));
    //         setFieldSorted(field);
    //         setOrderSorted(order);
    //         setSearch(search);

    //         setData(data);
    //     } else {
    //         setData([]);
    //     }            
    // }

    const apiGet = async (url: string) => {
        // setIsLoading(true);
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                mode:'cors'
            });

            if (!response.ok) {
                // setIsLoading(false);
                //navigate('/error');
                throw new Error('Network response was not ok');
            }

            // setIsLoading(false);
            return response;

        } catch (error) {
            // setIsLoading(false);
            //navigate('/network-error');
            console.error('Error fetching data:', error);
        }
    }

    return (
        <form className='flex flex-col gap-y-2'>
            <input type="text" onChange={handleChange}
                name='govTaxId'
                placeholder='CNPJ'
                className='px-4 py-2'
            />
            <input type="text" onChange={handleChange}
                name='fantasyName'
                placeholder='Nome fantasia'
                className='px-4 py-2'
            />
            <input type="text" onChange={handleChange}
                name='legalName'
                placeholder='Razão Social'
                className='px-4 py-2'
            />
        </form>
    );
};

export default PartnerFormPage;