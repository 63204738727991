import LegacyApp from "../../atoms/LegacyApp";

const SolidarityPartners = () => {

  console.log(process.env.REACT_APP_BACKOFFICE_URL)

  const legacyAppUrl = `${process.env.REACT_APP_BACKOFFICE_URL}/guarantor/all?embeeded=true`;

  return (
    <div className="app-container">
      <LegacyApp url={legacyAppUrl} />
    </div>
  );
};

export default SolidarityPartners;