
import SelectFilter from "../../organisms/SelectFilter"

import { Link, useNavigate, useParams } from "react-router-dom"
import PageList from "../../templates/PageList"
import PageSearch from "../../molecules/PageSearch"
import { useState } from "react"
import { FaUsers } from "react-icons/fa"

type PartnerManager = {
    id: number,
    partnerName: string,
    external: string,
    internal: string,
    associationDate: string,
    userResponsible: string,
}

const BASE_BACKOFFICE_URL = `${process.env.REACT_APP_BACKOFFICE_URL}/services/commercial`;

const CommercialPage = () => {

    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    // FILTROS DA TABELA //
    let filterManagerInt = ''
    let filterManagerExt = ''
    let filterPartner = ''
    let filterPartnerFetch = ''

    let voidManagerExt = false
    let voidManagerInt = false

    const [disabledSearch, setDisabledSearch] = useState(false)

    const optFilter = [{
        value: 'Gerente Externo',
        label: 'Gerente Externo'
    },
    {
        value: 'Gerente Interno',
        label: 'Gerente Interno'
    },
    {
        value: 'Parceira',
        label: 'Parceira'
    },
    {
        value: 'Parceira sem Gerente Interno',
        label: 'Parceira sem Gerente Interno'
    },
    {
        value: 'Parceira sem Gerente Externo',
        label: 'Parceira sem Gerente Externo'
    }]

    const [optFilterValue, setOptFilterValue] = useState('');
    // SELECTS DO FORMULARRIO //

    if (filter) {
        [filterPartner = '', filterManagerExt = '', filterManagerInt = '', voidManagerInt, voidManagerExt] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

        if (filterPartner !== '' && (filterManagerExt !== '' || filterManagerInt !== '')) {
            filterPartnerFetch = '';
        } else {
            filterPartnerFetch = filterPartner;
        }
    }

    const url = `${BASE_BACKOFFICE_URL}/association-list?search=${filterPartnerFetch}`
        + `&page=${page}&pageSize=${pageSize}&managerInt=${filterManagerInt}&managerExt=${filterManagerExt}&voidExt=${voidManagerExt}&voidInt=${voidManagerInt}`;


    const renderRows = (patnerManagers: PartnerManager[]) => {
        return patnerManagers.map((row, index) => {
            return (
                <tr key={index} className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                    <td className="py-2 px-2">{row.id}</td>
                    <td className="py-2 px-2" align="center">{row.partnerName}</td>
                    <td className="py-2 px-2" align="center">{row.external}</td>
                    <td className="py-2 px-2" align="center">{row.internal}</td>
                    <td className="py-2 px-2">{row.associationDate}</td>
                    <td className="py-2 px-2" align="center">{row.userResponsible}</td>
                </tr>
            )
        })
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNaviage(page, pageSize, fieldSorted, orderSorted, filterManagerExt, filterManagerInt, filterPartner, voidManagerExt, voidManagerInt), { replace: true });
    };

    const pageSorted = (field: string | string, order: 'ASC' | 'DESC') => {
        navigate(urlNaviage(page, pageSize, field, order, filterManagerExt, filterManagerInt, filterPartner, voidManagerExt, voidManagerInt), { replace: true });
    }

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, filterManagerExt, filterManagerInt, filterPartner, voidManagerExt, voidManagerInt), { replace: true });
    }

    const partnerSearch = (value: string) => {

        cleanFilters();

        filterPartner = value;

        const navigateToUrl = () => {
            navigate(
                urlNaviage(0, pageSize, fieldSorted, orderSorted, filterManagerExt, filterManagerInt, filterPartner, voidManagerExt, voidManagerInt),
                { replace: true }
            );
        };

        switch (optFilterValue) {
            case 'Gerente Externo':
                filterManagerExt = value;
                navigateToUrl();
                break;
            case 'Gerente Interno':
                filterManagerInt = value;
                navigateToUrl();
                break;
            case 'Parceira':
                filterPartner = value;
                navigateToUrl();
                break;
            default:
                setDisabledSearch(true);
                return;
        }
    };

    const onFilterClean = () => {
        cleanFilters();
        setOptFilterValue('');

        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, '', '', '', false, false), { replace: true });
    }

    const onchangeFiltro = (value: string) => {
        cleanFilters();
        setOptFilterValue(value)
        if (value === 'Parceira sem Gerente Interno') {
            navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, filterManagerExt, filterManagerInt, filterPartner, voidManagerExt, true), { replace: true });
        }

        if (value === 'Parceira sem Gerente Externo') {
            navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, filterManagerExt, filterManagerInt, filterPartner, true, voidManagerInt), { replace: true });
        }
    }

    const cleanFilters = () => {
        filterManagerExt = '';
        filterManagerInt = '';
        filterPartner = '';
        voidManagerInt = false
        voidManagerExt = false
    }

    const urlNaviage = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        managerExt: string, managerInt: string, partner: string, voidExt: boolean, voidInt: boolean) => {
        return `/commercial/${page}/${pageSize}/${fieldSorted}/${orderSorted}/p=${partner};me=${managerExt};mi=${managerInt};void-int=${voidInt};void-ext=${voidExt};`
    }

    return (
        <>

            <PageList
                pageTitle='Lista de Parceiras x Gerentes'
                url={url}
                breadcrumb={[{ label: 'Parceiros x Gerente', url: "#" }]}
                tableFieldSort={fieldSorted}
                tableOrderSort={orderSorted}
                onTableSort={pageSorted}
                renderRows={renderRows}
                page={page}
                pageSize={pageSize}
                onPageChange={pageChanged}
                onItemsPerPageChange={pageSizeChanged}
                itemsLabel='comercial'
            >
                {{
                    filterSlot: (
                        <div className="grid grid-cols-3 gap-1">
                            <SelectFilter
                                id="slc-filter-manager-ext"
                                label="Filtro"
                                value={optFilterValue}
                                onChange={onchangeFiltro}
                                defaultVoidOption={true}
                                options={optFilter}
                            />

                            <PageSearch
                                value={filterPartner}
                                disabled={disabledSearch}
                                label={`Procurar por ${optFilterValue}...`}
                                onSearch={partnerSearch}
                                filterCleanVisible={true}
                                filterCleanEnabled={(optFilterValue || filterPartner || filterManagerExt || filterManagerInt) ? true : false}
                                configureVisible={false}
                                onFilterClean={onFilterClean}
                            />
                        </div>),

                    controlSlot: (<div className='pt-4'>
                        <Link to={`/commercial-association`} >
                            <button type='button'
                                className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'
                            >

                                <div className="flex items-center">
                                    <FaUsers className='mr-2' />
                                    <span>Associar Gerentes x Parceiras</span>
                                </div>
                            </button>
                        </Link>
                    </div>)
                }}
            </PageList>

        </>
    );
}

export default CommercialPage