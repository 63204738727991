import { FC } from 'react';
import MenuItem from './MenuItem';
import AgrolendLogo from "../ions/AgrolendLogo";
import { AiOutlineLeft } from 'react-icons/ai';
import UserData from '../organisms/UserData';
import { FiTool } from "react-icons/fi";

interface MenuItem {
	key: string;
	external?: boolean;
	href: string;
	label: string;
	visible?: boolean;
}

interface SectionSidebarProps {
	title: string;
	menuItems: MenuItem[];
	currentSidebarTab: string | null;
	tabName: string;
	onCloseMenu: () => void;
	showConfigMenu?: boolean;
	urlConfigMenu?: string;
}

const SectionSidebar: FC<SectionSidebarProps> = ({
	title,
	menuItems,
	currentSidebarTab,
	tabName,
	onCloseMenu,
	showConfigMenu = false,
	urlConfigMenu = '',
}) => {

	const buttonCloseClick = () => onCloseMenu();

	return (
		<nav
			style={
				currentSidebarTab === tabName
					? { display: 'flex', flexDirection: 'column', height: '100%' }
					: {}
			}
			aria-label="Main"
			className={
				currentSidebarTab === tabName ? 'flex flex-col h-full bg-slate-100' : 'hidden'
			}
		>
			<div className="relative">
				<button className="absolute top-3 right-1 mt-2 p-2 rounded-full mouse transition ease-in duration-200 text-slate-500 hover:text-white z-90 hover:bg-slate-500 hover:shadow"
					onClick={buttonCloseClick} >
					<AiOutlineLeft />
				</button>
			</div>

			<div className="flex items-center justify-center flex-shrink-0 py-5 mb-5">
				<AgrolendLogo />
			</div>

			<h5 className="text-xs px-2 mb-2">{title}</h5>
			<div className="flex-1 px-2 space-y-2 overflow-hidden hover:overflow-auto">
				<ul>
					{menuItems.map((item) => (
						<MenuItem external={item.external}
							key={item.key}
							href={item.href}
							label={item.label}
							visible={item.visible}
						/>
					))}
				</ul>
			</div>

			<div className="flex flex-col items-center flex-1 p-2 space-y-4 flex-col-reverse">
				<ul>
					<MenuItem
						Icon={FiTool}
						key="config-menu"
						href={urlConfigMenu}
						label="Configurações"
						visible={showConfigMenu}
						external={false}
					/>
				</ul>
			</div>

			<UserData />
		</nav>
	);
};

export default SectionSidebar;
