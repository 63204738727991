import React, { InputHTMLAttributes, useEffect, useState, forwardRef } from 'react';
import InputFrame from '../atoms/InputFrame';

interface CurrencyInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    label: string;
    errorMessage?: string;
    className?: string;
    prefix?: string;
    value?: number;
    onChange?: (value: number) => void;
    fractionDigits?: number;
    textColor?: string;
    disabled?: boolean;
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
    ({
        label,
        errorMessage,
        className,
        prefix = 'R$',
        value = 0,
        onChange,
        fractionDigits = 2,
        textColor = "text-gray-900",
        disabled = false,
        ...restProps
    }, ref) => {
        const [displayValue, setDisplayValue] = useState(formatCurrency(value));

        useEffect(() => {
            setDisplayValue(formatCurrency(value));
        }, [value]);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, '');
            const formattedValue = formatCurrency(Number(numericValue) / Math.pow(10, fractionDigits));

            setDisplayValue(formattedValue);
            if (onChange) {
                onChange(Number(numericValue) / Math.pow(10, fractionDigits));
            }
        };

        function formatCurrency(value: number): string {
            if (fractionDigits !== 2) {
                return value.toFixed(fractionDigits).replace('.', ',');
            }
            return value.toLocaleString('pt-BR', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });
        }

        let focusColor = "focus:border-blue-600";
        let peerFocusColor = "peer-focus:text-blue-600";

        if (errorMessage) {
            textColor = "text-red-700";
            focusColor = "focus:border-red-600";
            peerFocusColor = "peer-focus:text-red-600";
        }

        return (
            <InputFrame
                label={label}
                errorMessage={errorMessage}
                prefix={prefix}
                className="mb-4"
                textColor={textColor}
            >
                <input
                    type="text"
                    ref={ref}
                    value={displayValue}
                    onChange={handleInputChange}
                    disabled={disabled}
                    className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 ${focusColor} peer`}
                    {...restProps}
                />
            </InputFrame>
        );
    }
);

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
