import { FC } from 'react';
import Breadcrumb from '../../atoms/Breadcrumb';
import NetworkErrorImage from '../../ions/NetworkErrorImage';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';

const NetworkError: FC = () => {

	const navigate = useNavigate();

	const items = [{
		label: "Home",
		url: "/"
	}, {
		label: "Erro de conexão",
		url: "#"
	}]

	return (
		<div className="flex flex-col h-screen">
			<div className="p-4">
				<Breadcrumb items={items} />
			</div>
			<div className="flex flex-grow items-center justify-center">
				<div className="text-center">
					<div className="flex items-center justify-center">
						<NetworkErrorImage />
						<div>
							<h1 className="text-5xl font-bold text-gray-700">Erro 503!</h1>
							<p className="text-2xl my-4">Servico indisponível!</p>
						</div>
					</div>
					<div className="mx-auto p-4 text-gray-600 text-center">
						<p className="mb-4">
							O serviço está fora do ar, em manutenção ou sendo atualizado.
							Por favor aguarde alguns minutos e tente acessar novamente.
						</p>
						<p>
							Se o problema persistir, entre em contato com a equipe de tech através
							do canal slack #agrolend-tech-support.
						</p>
						<button
							id='back-button'
							className="mt-4 px-3 py-2 text-xs font-medium text-center inline-flex text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-white focus:ring-offset-2"
							onClick={() => navigate(-3)}>
							<IoMdArrowBack className='mr-2' />
							Voltar para página anterior
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NetworkError;
