import { FC, Fragment } from "react";

type OptionProps = {
    value: string;
    label: string;
}

type SelectTextFieldProps = {
    id: string;
    name: string;
    label: string;
    errorMessage: string;
    onChange: (e: any) => void;
    items: OptionProps[];
    value: string;
    emptyValue?: string;
    emptyLabel?: string;
    disabled?: boolean;
}

const SelectTextField: FC<SelectTextFieldProps> = ({ id, name, label, errorMessage, items,
    value, onChange, emptyValue = '', emptyLabel = 'Selecione um registro...', disabled = false }) => {

    const error = errorMessage ? true : false;
    const borderColor = error ? "border-red-600" : "border-gray-300";

    return (
        <Fragment>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
            <select id={id}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 ${borderColor} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer disabled:opacity-50 disabled:cursor-not-allowed`}
            >
                <option value={emptyValue}>{emptyLabel}</option>

                {items.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            {error &&
                <p className='mt-2 text-sm text-red-600'>
                    {errorMessage}
                </p>}
        </Fragment>
    );
}

export default SelectTextField;