import { FC } from 'react';

import APagination from './APagination';

interface PageFooterProps {
    currentPage: number;
    totalPages: number;
    itemsPerPage: number;
    totalElements: number;
    onPageChange: (page: number) => void;
    onItemsPerPageChange?: (itemsPerPage: number) => void;
    itemsLabel?: string;
}

const PageFooter: FC<PageFooterProps> = ({
    currentPage,
    totalPages,
    itemsPerPage,
    totalElements,
    onPageChange,
    onItemsPerPageChange = () => { },
    itemsLabel = 'itens'
}) => {

    const firstItem = currentPage++ * itemsPerPage + 1;
    const lastItem = Math.min(currentPage * itemsPerPage, totalElements);

    const itemsPerPageOptions = [20, 40, 60, 80, 100, 200];

    return (
        <nav className="flex items-center h-14" aria-label="Table navigation">

            <div className="flex items-center space-x-4 border-r border-gray-300 pr-5 h-full ml-2">
                <label htmlFor="itemsPerPage" className='text-sm font-normal text-gray-500'>Itens por página: </label>
                <select
                    id="itemsPerPage"
                    className='text-sm block px-2 py-2 border-gray-300 text-gray-900 bg-transparent appearance-none peer w-16'
                    value={itemsPerPage}
                    onChange={(e) => {
                        const newItemsPerPage = parseInt(e.target.value, 10);
                        onItemsPerPageChange(newItemsPerPage);
                    }}
                >
                    {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>

            {totalElements > 0 &&
                <span className="text-sm font-normal text-gray-500 flex-1">
                    <span className="font-semibold text-gray-900 ml-4">{firstItem}</span>
                    <span> - </span>
                    <span className="font-semibold text-gray-900">{lastItem}</span>
                    <span> de </span>
                    <span className="font-semibold text-gray-900">{totalElements}</span>
                    <span> {itemsLabel} </span>
                </span>}

            {totalElements > 0 &&
                <APagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            }
        </nav>
    );
};

export default PageFooter;