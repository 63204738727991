import React, { InputHTMLAttributes } from 'react';

interface TextEditProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    errorMessage?: string;
    className?: string;
    prefix?: string;
}

const TextEdit: React.FC<TextEditProps> = ({ prefix, label, errorMessage, className, ...restProps }) => {

    let textColor = "text-gray-900";
    let focusColor = "focus:border-blue-600";
    let peerFocusColor = "peer-focus:text-blue-600";

    if (errorMessage) {
        textColor = "text-red-700";
        focusColor = "focus:border-red-600";
        peerFocusColor = "peer-focus:text-red-600";
    }

    return (
        <div className={`relative`}>
            <div className="flex items-center">
                {prefix && (
                    <span className="mr-2 text-sm text-gray-500">{prefix}</span>)}
                <input
                    className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 ${focusColor} peer`}
                    {...restProps}
                />
                <label
                    htmlFor={restProps.id}
                    className={`absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 ${peerFocusColor} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
                >
                    {label}
                </label>
            </div>
            {errorMessage && <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p>}
        </div>
    );
};

export default TextEdit;