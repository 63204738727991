import { FC } from 'react';
import NewTooltip from '../atoms/NewTooltip';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';

type CreditLimitDisplayProps = {
    creditLimitFormatted: string;
    limitCreditDivideCreditRuralInPercent: string | number;
    recommendation: string;
    creditRuralFormattedValue: string;
    creditRuralStatus?: string;
    className?: string;
};

const ReferralCreditLimitDisplay: FC<CreditLimitDisplayProps> = ({
    creditLimitFormatted = '0',
    limitCreditDivideCreditRuralInPercent = '0',
    creditRuralFormattedValue = '0',
    recommendation,
    creditRuralStatus = 'ok',
    className = '',
}) => {
    const formatPercent = (value: string | number): string => {
        const numericValue = typeof value === 'string' ? Number(value) : value;
        return isNaN(numericValue) ? String(value) : `${numericValue}%`;
    };

    const getColorClass = (value: string | number): string => {
        const numericValue = typeof value === 'string' ? Number(value) : value;

        if (isNaN(numericValue)) return 'text-red-600';
        if (numericValue <= 20) return 'text-green-600';
        if (numericValue <= 30) return 'text-yellow-600';
        return 'text-red-600';
    };

    const renderNewTooltipContent = () => {
        if (creditRuralStatus === 'error') {
            return (
                <NewTooltip content="Erro ao carregar crédito rural M0 do farmer analysis">
                    <FaTimes className="text-red-600" />
                </NewTooltip>
            );
        }

        if (creditRuralStatus === 'notfound') {
            return (
                <NewTooltip content="Crédito rural M0 não encontrado">
                    <FaExclamationCircle className="text-yellow-500" />
                </NewTooltip>
            );
        }

        return (
            <NewTooltip content={`${creditLimitFormatted} / ${creditRuralFormattedValue}`}>
                <span className={`mr-2 ${getColorClass(limitCreditDivideCreditRuralInPercent)} ${className}`}>
                    {formatPercent(limitCreditDivideCreditRuralInPercent)}
                </span>
            </NewTooltip>
        );
    };

    if (recommendation === 'processing') {
        return <span className={`mr-2 ${className}`}>-</span>;
    }

    return renderNewTooltipContent();
};

export default ReferralCreditLimitDisplay;
