

function TableLoading(){
    return (
        <div className="absolute bg-gray-100 bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div className="flex items-center flex-col">
                <h2 className="text-3xl mr-4">Carregando...</h2>
                <p className=" text-center text-gray-500">
                    Por favor aguarde enquanto a página carrega o conteúdo.
                </p>
            </div>
        </div>
    );
}

export default TableLoading;