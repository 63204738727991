import { ReactNode } from 'react';
import TwoSidebar from '../organisms/TwoSidebar';
import { ToastContainer } from 'react-toastify';

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="flex">
            <div id="menu-partner-analysis-list" className='bg-red-500 bg-[#2563eb] bg-[#9f1239] bg-[#059669] bg-[#b91c1c]'></div>
            <div className='bg-green-500 bg-green-600 bg-green-700'></div>
            <div className='bg-blue-500'></div>
            <div className='bg-gray-800 bg-pink-500 bg-pink-600 to-pink-700'></div>
            <TwoSidebar />
            <ToastContainer
                stacked={true}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />
            <div style={{ width: "100%" }}>
                {children}
            </div>
        </div>
    );
};

export default Layout;