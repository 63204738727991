import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './AppRoutes';
import AuthProvider from './services/Auth/AuthProvider';
import { Amplify } from 'aws-amplify';
import amplifyConfig from './config/amplify-config';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

Amplify.configure(amplifyConfig);

root.render(
	<React.StrictMode>
		<AuthProvider>
			<AppRoutes />
		</AuthProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
